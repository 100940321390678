@import "global";

.full-height {
    height: 100%;
}

.full-vh {
	height:	100vh;
	.card-img-top {
		width: 50%;
		margin: 1rem auto;
	}
}

.container-mini {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container-mini {
        max-width: 540px;
    }
}

@media (min-width: 992px) {
    .full-height, .full-height-lg {
        height: 100%;
    }
    .container-mini {
        max-width: 720px;
    }
}

@media (min-width: 1200px) {
    .container-mini {
        max-width: 960px;
    }
}

@media (min-width: 1400px) {
	.pb-xxl-5, .py-xxl-5 {
	    padding-bottom: 3rem !important;
	}
	.mb-xxl-5, .my-xxl-5 {
	    margin-bottom: 3rem !important;
	}
    .container-mini {
        max-width: 1140px;
    }
	.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
	    max-width: 1320px;
	}
}

@media (min-width: 1900px) {
    .container-mini {
        max-width: 1320px;
    }
	.container-3xl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
	    max-width: 1680px;
	}
}